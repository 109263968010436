
.reactMarkdown h1 {
    font-size:35px;
    margin:0;
    padding:0;
    line-height: 1;
}

.reactMarkdown p {
    padding: 0;
    line-height: 1.5;
}

.reactMarkdown ul {
    padding: 0 35px;
}
@media (max-width: 768px) {
    .reactMarkdown ul {
        padding: 0 10px;
    }
    .reactMarkdown h1 {
        font-size:35px;
        line-height: 1.2;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .reactMarkdown ul {
        padding: 0 35px;
    }
    .reactMarkdown h1 {
        font-size:45px;
    }
}

.reactMarkdown body {
    background-color: #323437;
    font-family: Consolas, serif;
    color: ghostwhite;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    line-height: 1.6;
}

.reactMarkdown  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }
